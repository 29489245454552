import React, { FunctionComponent } from "react"
import styled from 'styled-components';
import Grid from "../Grid/Grid";
import Row from "../Grid/Row";
import Col from '../Grid/Col';

interface ModalViewPortProps {
  show?: boolean;
  children: any;
  handleClose?: any
}

const ModalViewPort = styled.div<ModalViewPortProps>`
  z-index: 5555;
  display: ${({show}) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
`

const ModalContainerCenter = styled.div`
  position: fixed;
	background: #eee;


  width: 80vh;
  top: 10%;
  left: 50%;
  transform: translate(-50%,0);
  border-radius: 10px;
	padding: 0.75rem;

`


export const Modal: FunctionComponent<ModalViewPortProps> = (props: ModalViewPortProps) => {

  const handleClose = () => {
    props?.handleClose();
  }


  return (
    <ModalViewPort show={props.show} onClick={handleClose}>
      <ModalContainerCenter>
        {/* <ModalSubContainer> */}
          <Grid>
            <Row>
              <Col size={1}>
              {props.children}
              </Col>
            </Row>
          </Grid>
      {/* </ModalSubContainer> */}
      </ModalContainerCenter>
    </ModalViewPort>
  )
}
