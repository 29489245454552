import styled from 'styled-components';


interface ColProps {
  size?: number;
  collapse?: 'xs' | 'md'
}

const media = {
  xs: (styles: any) => `
  @media only screen and (max-width: 900px) {
    ${styles}
  `,
  md: (styles: any) => `
  @media only screen and (min-width: 900px) {
    ${styles}
  `
}

const Col = styled.div<ColProps>`
  flex: ${(props) => props.size} 100%;
  ${(props) => props.collapse && media[props.collapse](`
    display: none
  `)};
`

export default Col;
