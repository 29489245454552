import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Item from "../../models/collections/Item"
import { Layout } from '../../components/Layout';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import Helmet from 'react-helmet';
import { Header } from '../../components/Header';
import { SectionTitle } from '../../components/SectionTitle';
import { Subline } from '../../components/Subline';
import { Wrapper } from '../../components/Wrapper';
import { Content } from '../../components/Content';
import styled from 'styled-components';
import SiteConfig from '../../../config/SiteConfig';
import Row from '../../components/Grid/Row';
import Col from '../../components/Grid/Col';
import Grid from '../../components/Grid/Grid';
import { Modal } from '../../components/Modal/Modal';
import SimpleReactLightbox from 'simple-react-lightbox'


const ItemContent = styled.div`
    margin-top: 2rem;
`

interface Props {
  data: {
      markdownRemark: Item
  }

}
const ItemTemplate = (props: Props) => {
  const { html } = props.data.markdownRemark;
  const {date, title, images } = props.data.markdownRemark.frontmatter;

  const smallImages = images.map(({small}: {small: any}) => getImage(small))
  const largeImages = images.map(({large}: {large: any}) => getImage(large))
  const extraLargeImages = images.map(({xlarge}: {xlarge: any}) => getImage(xlarge))

  const [selectedImage, setSelectedImage] = useState(largeImages[0])
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [modalOpened, setModalOpened] = useState(false)

  const handleImageHover = (index: number) => {
    setSelectedImageIndex(index)
    setSelectedImage(largeImages[index])
  }

  const getMobilePreviewCount = () => {
    return smallImages.length < 3 ? smallImages.length : 3
  }

  const getLargePreviewCount = () => {
    return smallImages.length < 3 ? 2 : 3
  }


  const Item = (props: any) => {
    const {e, idx, shadow} = props;
    if(shadow) {
      return <GatsbyImage key={`${idx}-image`} image={e} alt="" onMouseEnter={() => {handleImageHover(idx)}} style={{boxShadow: "none"}} onClick={() => {setModalOpened(true)}}/>
    } else {
      return  <GatsbyImage key={`${idx}-image`} image={e} alt="" onMouseEnter={() => {handleImageHover(idx)}} style={{boxShadow: "#eee 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"}} onClick={() => {setModalOpened(true)}}/>
    }
  }

  const Thumbnails = () => {
    return (
      <ResponsiveMasonry
      columnsCountBreakPoints={{350: getMobilePreviewCount(), 900: 2, 1200: getLargePreviewCount()}}

    >
      <Masonry gutter={'5px'}>
        {smallImages.map((e: any, idx: number) => {
            return <Item e={e} idx={idx} shadow={idx !== selectedImageIndex}/>
        })}
      </Masonry>
    </ResponsiveMasonry>
    )
  }


  return (
    <Layout>
            {/* <SEO postPath={item.fields.slug} postNode={post} postSEO /> */}
            <Helmet title={`${title} | ${SiteConfig.siteTitle}`} />
            <Header>
                <Link to="/">Home</Link>
                <SectionTitle>{title}</SectionTitle>
                <Subline light={true}>
                    Date acquired: {date}
                </Subline>
                <Link to={`/collections`}> &lt; Back to Collections</Link>
            </Header>
            <Wrapper fullWidth={false}>
              <Content>

                <Grid>
                  <Row>
                    <Col size={1}>
                    {selectedImage && <GatsbyImage image={selectedImage} alt="" onClick={() => {setModalOpened(true)}}/>}
                      </Col>
                    <Col size={1} collapse={'xs'} style={{paddingLeft: "15px"}}>
                       {Thumbnails()}
                    </Col>
                  </Row>
                  <Row>
                    <Col size={1} collapse={'md'}>
                  {Thumbnails()}
                      </Col>
                  </Row>

                </Grid>
                <ItemContent dangerouslySetInnerHTML={{ __html: html }} />
             </Content>
            </Wrapper>
            <Modal show={modalOpened} handleClose={() => {setModalOpened(false)}}>
              <div>
              <GatsbyImage image={extraLargeImages[selectedImageIndex]} alt="" style={{width: '100%', height: 'auto'}}/>
              </div>
            </Modal>
  </Layout>
  )
}

export const itemQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "DD.MM.YYYY")

              images {
                small: childImageSharp {
                    gatsbyImageData(
                    width: 266
                    height: 266
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      fit: COVER, cropFocus: CENTER
                    }

                  )
                }
                large: childImageSharp {
                    gatsbyImageData(
                    width: 650
                    height: 650
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    quality: 75
                    transformOptions: {
                      fit: COVER,
                      cropFocus: CENTER
                    }
                  )
                }
                xlarge: childImageSharp {
                    gatsbyImageData(
                      transformOptions: {
                      fit: FILL, cropFocus: CENTER
                    }
                    breakpoints: [750, 1080, 1366, 1920]
                      quality: 80
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF],

                  )
                }
            }
        }
    }
    }
`


export default ItemTemplate
